import React from 'react'
import LanguageSwitcher from '../../components/LanguageSwitcher'
import { useTranslation } from 'react-i18next';


export default function Profile() {
    const { t } = useTranslation();
    return (
        <div>
            <div>
                <h1>{t('welcome')}</h1>
                <p>{t('greeting', { name: 'John' })}</p>
            </div>
            <LanguageSwitcher />
        </div>
    )
}
