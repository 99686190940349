import React, { Component } from 'react'
import { Button, Container,Divider,Grid,Toolbar, Typography } from '@mui/material';
import NavBar from '../components/NavBar';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

export default class ServicePriceDetail extends Component {
  render() {
    return (
        <Container direction="column"
        justifyContent="space-evenly"
        alignItems="center" maxWidth="xl">
        <NavBar/>
        <Toolbar />
        <Grid container p={6} spacing={6}>
            <Grid item xs={12}  md={8}>
                <Typography variant="h4">
                    Basic Plan 
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    19$/Month
                </Typography>
                <Divider/>
                <Typography variant="h5" gutterBottom>
                    Voice SaaS on Microsoft Teams :
                </Typography>
                <Typography variant="body1" gutterBottom >
                This service likely involves providing a Software-as-a-Service (SaaS) solution that enables voice communication within Microsoft Teams. This could include features like call management, voicemail, call routing, and possibly integration with existing telephony systems. Ensure your solution offers high-quality voice calls, security, and seamless integration with Teams' interface.
                </Typography>
                <Typography variant="h5" gutterBottom>
                    Mobile Number on Microsoft Teams:
                </Typography>
                <Typography variant="body1" gutterBottom>
                This service might involve enabling users to use their mobile phone numbers for communication within Microsoft Teams. This could be achieved through integration with telecommunications providers and Teams' APIs. Users could make and receive calls, send text messages, and potentially even access other mobile-related features directly within the Teams interface.                </Typography>
                <Typography variant="h5" gutterBottom>
                    Microsoft Teams Direct Routing Integration:
                </Typography>
                <Typography variant="body1" gutterBottom>
                Microsoft Teams Direct Routing allows organizations to connect their own telephony infrastructure to Microsoft Teams, enabling them to make and receive external phone calls through the Teams platform. Your company could provide integration services to help businesses set up and manage this functionality efficiently.                </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
                You like what you’re reading?
                </Typography>
            <Button startIcon={<CloudDownloadIcon />} variant="contained">
                Download quote 
            </Button>
            </Grid>
        </Grid>
      
      </Container>
    )
  }
}
