// LanguageSelector.js

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import NativeSelect from '@mui/material/NativeSelect';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

function LanguageSelector() {
  const { i18n } = useTranslation();
  const [defaultLanguage, setDefaultLanguage] = useState('');

  useEffect(() => {
    const browserLanguage = navigator.language.split('-')[0]; // Get the browser language
    const supportedLanguages = ['en', 'fr']; // Add more languages as needed
    const defaultLang = supportedLanguages.includes(browserLanguage) ? browserLanguage : 'en'; // Fallback to 'en' if browser language is not supported

    i18n.changeLanguage(defaultLang); // Set the language for i18n
    setDefaultLanguage(defaultLang); // Set the default language for the Select component
  }, [i18n]);

  const handleChangeLanguage = (event) => {
    console.log( event.target.value)
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
    setDefaultLanguage(selectedLanguage); // Update the default language
  };

  return (
    <FormControl sx={{ m: 0.5}} size="small">
      <NativeSelect size="small"
        value={defaultLanguage}
        onChange={handleChangeLanguage}
        sx={{ fontSize: "0.9rem"}}
      >
        <option  value="en">En</option >
        <option  value="fr">Fr</option >
        {/* <MenuItem value="ar">Ar</MenuItem> */}
        {/* Add more languages as needed */}
      </NativeSelect>
    </FormControl>
  );
}

export default LanguageSelector;
