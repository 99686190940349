// About1.js

import React from 'react';
import { Typography, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import about1Image from '../../../assets/images/about1.png';
import { useNavigate } from 'react-router-dom';

const About1 = ({ handleNavItemClick }) => {
  const { t } = useTranslation('about1');
  const navigate = useNavigate();

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Typography noWrap variant="h4" component="h4" gutterBottom>
          {t('title')}
        </Typography>
        <Typography variant="h6" component="h6" gutterBottom>
          {t('subtitle')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('description')}
        </Typography>
        <Button variant="contained" onClick={() => { navigate("/ReadMore") }} className="button" color="primary">
          {t('readMore')}
        </Button>
        <Button variant="outlined" className="button" onClick={() => handleNavItemClick("contact")} color="primary">
          {t('contactUs')}
        </Button>
      </Grid>
      <Grid item xs={12} sm={6} className="imageColumn">
        <img src={about1Image} alt="Image" sx={{ maxWidth: '100%', height: 'auto', transform: { xs: 'none', md: 'rotate(-25deg)' } }} />
      </Grid>
    </>
  )
}

export default About1;
