// userService.js

import api from '../../../interceptor';

// Function to create a new user
export async function createUser(user) {
  try {
    const response = await api.post('/users', user);
    return response.data;
  } catch (error) {
    throw new Error('Failed to create user');
  }
}

// Function to update an existing user
export async function updateUser(userId, updatedUser) {
  try {
    const response = await api.put(`/users/${userId}`, updatedUser);
    return response.data;
  } catch (error) {
    throw new Error('Failed to update user');
  }
}

// Function to fetch a list of users
export async function getUsers() {
  try {
    const response = await api.get('/users');
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch users');
  }
}

// Function to delete a user by ID
export async function deleteUser(userId) {
  try {
    const response = await api.delete(`/users/${userId}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to delete user');
  }
}