import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, Grid, Box, Paper } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { Link, useNavigate, Navigate } from 'react-router-dom';
import AuthService from './authService';
import Alert from '@mui/material/Alert';

const AuthPage = () => {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(true);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    emailConfirmation: '',
    password: '',
  });
  const [error,setError] = useState(false)
  const [errorMsg,setErrorMsg] = useState()

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLogin) {
      try {
        const response = await AuthService.login(formData.username, formData.password);
        localStorage.setItem('authToken', response.token);
        return navigate('/dashboard');
      } catch (error) {
        console.log(error);
        if(error.response.status === 401){
          setErrorMsg("Incorrect username or password")
        }

      }
    } else {
      try {
        const response = await AuthService.register(formData);
        localStorage.setItem('authToken', response.token);
        navigate("/dashboard");
      } catch (error) {
        console.log(error);
        setErrorMsg(error)
      }
    }
  };

  const switchAuthMode = () => {
    setIsLogin((prev) => !prev);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  useEffect(() => {
    window.addEventListener('keypress', handleKeyPress);
    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [formData, isLogin]);

  return (
    !AuthService.isAuthenticated() ? (
      <Container maxWidth="sm">
        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ padding: 3 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                <LockIcon fontSize="large" color="primary" />
                <Typography variant="h4" align="center" gutterBottom>
                  {isLogin ? 'Login' : 'Registration'}
                </Typography>
                <p>
                  {isLogin ? "Don't have an account? " : 'Already have an account? '}
                  <Link onClick={switchAuthMode}>{isLogin ? 'Register' : 'Login'}</Link>
                </p>
                {errorMsg&&<Alert severity="error">{errorMsg}</Alert>}
                <TextField
                  type="text"
                  name="username"
                  label="Username"
                  variant="outlined"
                  fullWidth
                  value={formData.username}
                  onChange={handleChange}
                  required
                />
                {!isLogin && (
                  <TextField
                    type="email"
                    name="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                )}
                {!isLogin && (
                  <TextField
                    type="email"
                    name="emailConfirmation"
                    label="Confirm Email"
                    variant="outlined"
                    fullWidth
                    value={formData.emailConfirmation}
                    onChange={handleChange}
                    required
                  />
                )}
                <TextField
                  type="password"
                  name="password"
                  label="Password"
                  variant="outlined"
                  fullWidth
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleSubmit}
                >
                  {isLogin ? 'Login' : 'Register'}
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    ) : (
      <Navigate to="/dashboard" />
    )
  );
};

export default AuthPage;
