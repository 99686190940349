import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MUILink from '@mui/material/Link';
import Button from '@mui/material/Button';
import Logo from '../assets/images/logo.png'

import { Collapse, Grid, Icon} from '@mui/material';
import telegramIcon from '../assets/images/telegram.png';
import instagramIcon from '../assets/images/instagram.png';
import twitterIcon from '../assets/images/twitter.png';
import facebookIcon from '../assets/images/facebook.png';
import whatsappIcon from '../assets/images/whatsapp.png';
import linkedinIcon from '../assets/images/linkedin.png';
import email from '../assets/images/email.png';
import SearchIcon from '@mui/icons-material/Search';
import PhoneIcon from '@mui/icons-material/Phone';

import LanguageSelector from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';

import { Link, useNavigate } from 'react-router-dom';

const drawerWidth = 240;
const navItems = ['home', 'about', 'services', 'contact'];

const NavBar = ({ handleNavItemClick, activeNavItem, socialNavVisible }) => {
    const { t } = useTranslation('navbar');
    const navigate = useNavigate();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    if (!handleNavItemClick) {
        handleNavItemClick = (item) => {
            return navigate('/');
        }
    }

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography component={Link} color="inherit" variant="h6" sx={{ my: 2 }}>
                EVO
            </Typography>

            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center', textTransform: 'capitalize',paddingBottom:'0' }}
                            onClick={() => handleNavItemClick(item)}
                            className={activeNavItem === item ? 'active' : ''}
                        >
                            <ListItemText primary={t(`${item}`)} />
                        </ListItemButton>
                    </ListItem>
                ))}
                <ListItem key={99} disablePadding>
                    <ListItemButton sx={{ textAlign: 'center' }}
                        component={Link} // Use Link component for navigation
                        to="/AuthPage"      // Specify the login page's route
                    >
                        <ListItemText primary={t('login')} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window.document.body : undefined;
    const emailIcon = (
        <Icon>
            <img alt="email" src={email} />
        </Icon>
    );
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            <AppBar  component="nav">
                <Collapse in={socialNavVisible}>
                    <Grid sx={{ pl: { xs: 4, md: 18 } }}
                        container columnSpacing={4} alignItems="center"
                        style={{ backgroundColor: "#2A2E60", height: "40px", color: "#FFFFFF" }}>
                        <Grid item>
                            <img alt="" src={twitterIcon} height="24px" width="24px" />
                        </Grid>
                        <Grid item >
                            <img alt="" src={linkedinIcon} height="24px" width="24px" />
                        </Grid>
                        <Grid item sx={{ flexGrow: 1 }} >
                            <img alt=""  src={instagramIcon} height="24px" width="24px" />
                        </Grid>
                        <Grid item sx={{ display: { xs: 'none', sm: 'block' } }}>
                            <img alt="" src={email} height="24px" width="24px" />
                        </Grid>
                        <Grid  ml={-4} item sx={{ display: { xs: 'none', sm: 'block' } }} >
                            <Button
                                variant="text"
                                target="_top"
                                rel="noopener noreferrer"
                                href={`mailto:Contact@evo.co.ma`}
                            >
                                <Typography style={{ fontSize: '0.6rem' }}>
                                Contact@evo.ma
                                </Typography>
                            </Button>
                        </Grid>
                        <PhoneIcon sx={{ display: { xs: 'none', sm: 'block' } }} />
                        <Grid ml={-4} mr={4} item sx={{ display: { xs: 'none', sm: 'block' } }}>
                            <Typography style={{  fontSize: '0.6rem' }}>
                            <MUILink underline="none" href="tel:+212663743638">
                            +212 663 743 638
                                </MUILink>
                            </Typography>
                        </Grid>
                    </Grid>
                </Collapse>
                <Toolbar variant="dense" sx={{ borderBottom: "2px solid #FF9100 !important" }}>

                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { xs: 'block', sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box component={Link} to="/" pl={6} sx={{ flexGrow: 1 }}>
                        <img alt="" src={Logo} style={{ paddingTop: "6px",width: "100px" }} />
                    </Box>

                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navItems.map((item) => (
                            <Button key={item}
                                sx={{ mr: 6 }}
                                onClick={() => handleNavItemClick(item)}
                                className={activeNavItem === item ? 'active' : ''}
                            >
                                {t(`${item}`)}
                            </Button>
                        ))}
                        <LanguageSelector/>
                    </Box>

                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
        </Box>
    );
}

NavBar.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default NavBar;
