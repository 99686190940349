// AuthService.js
import api from '../../interceptor';

const AuthService = {
  login: async (username, password) => {
    try {
      const response = await api.post('/auth/login', { username, password });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  register: async (userData) => {
    try {
      const response = await api.post('/auth/register', userData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  isAuthenticated : ()=> {
    return localStorage.getItem('authToken')? true : false
  },

  logout: () => {
    // Clear the auth token from local storage
    localStorage.removeItem('authToken');
  },
};

export default AuthService;
