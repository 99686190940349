import CodeIcon from '@mui/icons-material/Code';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import SecurityIcon from '@mui/icons-material/Security';

export const images = [
    {
      src: 'https://img.freepik.com/premium-photo/top-view-office-desk-with-computer-laptop-coffee-cup-notebook_35674-5971.jpg?w=826',
      title: 'Custom Solutions : Innovating Your Digital Landscape',
      description: 'Our seasoned team employs cutting-edge technologies and agile practices to create customized software solutions that precisely fit your business needs. From inception to deployment, we prioritize collaboration and innovation to deliver efficient, user-friendly applications that propel your business forward.',
    },
    {
      src: 'https://img.freepik.com/premium-photo/office-worker-accessories-flat-lay-blank-notepad-with-laptop-glasses-pen-grey-wooden-table_274234-3940.jpg?w=740',
      
      title: 'The Future of Collaboration',
      description: 'Pioneers cutting-edge collaboration solutions, revolutionizing teamwork with innovative technology. Empower your business with seamless communication and unparalleled efficiency.',
    },
    {
      src: 'https://img.freepik.com/premium-photo/blank-notepad-with-pen-wooden-table-laptop-cup-coffee-workplace-top-view_340270-363.jpg?w=740',
      title: 'The Experts are here',
      description: 'Comprehensive network architecture and robust security protocols ensure the integrity and protection of your digital assets against evolving threats, providing peace of mind and uninterrupted operations',
    },
  ];
  
  export const cards = [
    { icon: <CodeIcon  sx={{ fontSize: 40 }}/>, text: 'Developement Informatique' },
    { icon: <LocalPhoneIcon   sx={{ fontSize: 40 }}/>, text: 'Communications Uifiées' },
    { icon: <SecurityIcon  sx={{ fontSize: 40 }}/>, text: 'Reseaux et Securite Informatique' },
  ];
  