// ContactUs.js

import React, { useState } from 'react';
import { Typography, Grid, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './ContactUs.css'
import ContactUsDialog from './ContactUsDialog';
import MessageDialog from '../../../components/MessageDialog';

const ContactUs = ({ onSnackbarOpen }) => {
  const { t } = useTranslation('contactUs');

  const handleOpenContactDialog = () => {
    setOpenContactDialog(true);
  };

  const handleCloseContactDialog = () => {
    setOpenContactDialog(false);
  };

  const [openContactDialog, setOpenContactDialog] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ContactUsDialog onSnackbarOpen={onSnackbarOpen} open={openContactDialog} onClose={handleCloseContactDialog} />
      <MessageDialog handleClickOpen={handleClickOpen} handleClose={handleClose} open={open} />
      <Grid item md="12" xs="12">
        <Typography sx={{ pb: 6 }} variant="h4" component="h4" gutterBottom>
          {t('title')}
        </Typography>
      </Grid>
      <Grid container item md="12" spacing={6} direction="row" justifyContent="space-evenly" alignItems="center">
        <Grid item md="3" sx={{ mt: { xs: 6, md: 0 } }} id="needhelp">
          <div className="icon"></div>
          <Typography variant="h6">{t('byPhone.title')}</Typography>
          <Typography variant="body2">{t('byPhone.description')}</Typography>
        </Grid>
        <Grid item md="3" sx={{ mt: { xs: 6, md: 0 } }} id="start-case">
          <div className="icon"></div>
          <Typography variant="h6">{t('startCase.title')}</Typography>
          <Typography variant="body2">{t('startCase.description')}</Typography>
          <Button className="button" onClick={handleOpenContactDialog}>{t('startCase.startHere')}</Button>
        </Grid>
        <Grid item md="3" sx={{ mb: { xs: 6, md: 0 }, mt: { xs: 6, md: 0 } }} id="livechat">
          <div className="icon"></div>
          <Typography variant="h6">{t('liveChat.title')}</Typography>
          <Typography variant="body2">{t('liveChat.description')}</Typography>
          <Button className="button" onClick={handleClickOpen}>{t('liveChat.startChat')}</Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ContactUs;
