// OurServices.js

import React from 'react';
import { Typography, Button, Card, CardContent, Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SchoolIcon from '@mui/icons-material/School';
import PhoneIcon from '@mui/icons-material/Phone';
import ComputerIcon from '@mui/icons-material/Computer';
import './OurServices.css';

const OurServices = ({ handleNavItemClick }) => {
  const { t } = useTranslation('ourServices');

  const services = [
    {
      icon: <DesignServicesIcon sx={{ fontSize: 50, color: "#FF9100" }} />,
      title: t('services.0.title'),
      description: t('services.0.description')
    },
    {
      icon: <SettingsInputComponentIcon sx={{ fontSize: 50, color: "#FF9100" }} />,
      title: t('services.1.title'),
      description: t('services.1.description')
    },
    {
      icon: <ComputerIcon sx={{ fontSize: 50, color: "#FF9100" }} />,
      title: t('services.2.title'),
      description: t('services.2.description')
    },
    {
      icon: <SupportAgentIcon sx={{ fontSize: 50, color: "#FF9100" }} />,
      title: t('services.3.title'),
      description: t('services.3.description')
    },
    {
      icon: <SchoolIcon sx={{ fontSize: 50, color: "#FF9100" }} />,
      title: t('services.4.title'),
      description: t('services.4.description')
    },
    {
      icon: <PhoneIcon sx={{ fontSize: 50, color: "#FF9100" }} />,
      title: t('services.5.title'),
      description: t('services.5.description')
    },
  ];

    // JSON-LD structured data for services
    const structuredData = {
      "@context": "https://schema.org",
      "@type": "Service",
      "provider": {
        "@type": "Organization",
        "name": "Evo Technologies"
      },
      "serviceType": services.map((service, index) => ({
        "@type": "ServiceType",
        "name": service.title,
        "description": service.description
      }))
    };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Grid container item xs={12} justify="center">
      <Grid item xs={12}>
        <Typography variant="h2" className="title">
          {t('title')}
        </Typography>
      </Grid>
      <Grid container item className="our-services-section">
        <Grid item xs={12}>
          <Slider {...settings}>
            {services.map((service, index) => (
              <Card key={index} sx={{ borderRadius: "10%" }}>
                <CardContent>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-evenly"
                    alignItems="stretch"
                    className="box-container"
                  >
                    <Box className="square-box">
                      <div className="icon-container">
                        {service.icon}
                        <Typography variant="h5" color="textSecondary" component="h5">
                          {service.title}
                        </Typography>
                      </div>

                      <div className="text-container">
                        <Typography variant="body2" color="textSecondary" component="p">
                          {service.description}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                </CardContent>
              </Card>
            ))}
          </Slider>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" onClick={() => handleNavItemClick("contact")} color="primary" className="button">
          {t('learnMore')}
        </Button>
      </Grid>
      <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
    </Grid>
  );
};

export default OurServices;
