import React from 'react';
import { Typography, Container, Grid, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const footerStyles = {
  background: '#333', // Adjust background color as needed
  color: '#fff', // Adjust text color as needed
  padding: '20px 0',
  scrollSnapAlign: 'start',
  scrollSnapStop: 'always'
};

const Footer = () => {
  return (
    <footer style={footerStyles}>
      <Container maxWidth="md">
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="body1">&copy; {new Date().getFullYear()} EVO</Typography>
          </Grid>
          <Grid item>
            <IconButton color="inherit" href="#">
              <FacebookIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton color="inherit" href="#">
              <TwitterIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton color="inherit" href="#">
              <LinkedInIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;