// About3.js

import React from 'react';
import { Typography, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import about3Image from '../../../assets/images/about3.png';

const About3 = ({ toContactUsSection }) => {
  const { t } = useTranslation('about3');

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Typography variant="h4" component="h4" gutterBottom>
          {t('title')}
        </Typography>
        <Typography variant="h6" component="h6" gutterBottom>
          {t('subtitle')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('description')}
        </Typography>
        <Button variant="contained" onClick={() => toContactUsSection()} className="button" color="primary">
          {t('contactUs')}
        </Button>
      </Grid>
      <Grid item xs={12} sm={6} className="imageColumn">
        <img src={about3Image} alt="Image" style={{ maxWidth: '100%', height: 'auto' }} />
      </Grid>
    </>
  )
}

export default About3;
