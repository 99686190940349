// About4.js

import React from 'react';
import { Typography, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import about4Image from '../../../assets/images/about4.png';

const About4 = ({ toContactUsSection }) => {
  const { t } = useTranslation('about4');

  return (
    <>
      <Grid item xs={12} sm={6} className="imageColumn">
        <img src={about4Image} alt="Image" style={{ maxWidth: '60%', height: 'auto' }} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="h4" component="h4" gutterBottom>
          {t('title')}
        </Typography>
        <Typography variant="h6" component="h6" gutterBottom>
          {t('subtitle')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('description')}
        </Typography>
        <Button variant="contained" onClick={() => toContactUsSection()} className="button" color="primary">
          {t('contactUs')}
        </Button>
      </Grid>
    </>
  )
}

export default About4;
