// ContactUsDialog.js

import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import ContactUsForm from './ContactUsForm';
import { useTranslation } from 'react-i18next';

const ContactUsDialog = ({ open, onClose, onSnackbarOpen }) => {
  const { t } = useTranslation('contactUsDialog');

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('title')}</DialogTitle>
      <DialogContent>
        <ContactUsForm onSnackbarOpen={onSnackbarOpen} onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
};

export default ContactUsDialog;
