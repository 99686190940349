// Hero.js

import React, { useState, useRef } from 'react';
import './Hero.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { images, cards } from './carouselData';

export default function Hero() {
  const { t,i18n } = useTranslation(['hero', 'customWords'])
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(1);
  const [swipeDirection, setSwipeDirection] = useState(null);
  const touchStartX = useRef(null);
  const touchEndX = useRef(null);

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    const deltaX = touchEndX.current - touchStartX.current;
    if (deltaX > 50) {
      // Swipe right, move to previous slide
      setSwipeDirection('right');
      prevSlide();
    } else if (deltaX < -50) {
      // Swipe left, move to next slide
      setSwipeDirection('left');
      nextSlide();
    }
    setTimeout(() => {
      setSwipeDirection(null);
    }, 500); // Reset swipe direction after 500ms
  };

  const updateSlide = (index) => {
    setCurrentSlide(index);
  };

  const prevSlide = () => {
    setCurrentSlide((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const nextSlide = () => {
    setCurrentSlide((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div
      className={`carousel-container ${swipeDirection ? 'swipe-animation' : ''}`}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className="carousel">
        {images.map((image, index) => (
          <div
            key={index}
            className={`carousel-img-container ${
              index === currentSlide ? 'active' : ''
            }`}
            onClick={() => updateSlide(index)}
          >
            <img src={image.src} alt="" className="carousel-img" />
            <div className="carousel-text" id={`carousel-text${index}`}>
              <h1>{t(`images.${index}.title`)}</h1>
              <p>{t(`images.${index}.description`)}</p>
              <button
                className="read-more"
                onClick={() => {
                  navigate("/ReadMore");
                }}
              >
                {t('readMore', { ns: 'customWords' })}
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="card-container">
        {cards.map((card, index) => (
          <div
            key={index}
            className={`card ${index === currentSlide ? 'active' : ''}`}
            onClick={() => updateSlide(index)}
          >
            <span className="card-icon">{card.icon}</span>
            <span className="card-text">{t(`cards.${index}.text`)}</span>
          </div>
        ))}
      </div>
      <IconButton className={`arrow prev`} onClick={prevSlide}>
        <ChevronLeft />
      </IconButton>
      <IconButton className={`arrow next`} onClick={nextSlide}>
        <ChevronRight />
      </IconButton>
      <div className="indicators">
        {images.map((image, index) => (
          <div
            key={index}
            className={`indicator ${index === currentSlide ? 'active' : ''}`}
            onClick={() => updateSlide(index)}
          ></div>
        ))}
      </div>
    </div>
  );
}
