// interceptor.js
import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Request interceptor for adding an authorization header
api.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem('authToken');
    console.log(authToken)
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for handling errors globally
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle unauthorized access (e.g., redirect to login)
      // You can also clear the auth token from local storage here
    }
    return Promise.reject(error);
  }
);

export default api;
