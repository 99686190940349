import React, { Component } from 'react';
import { Typography, Container, Button, Paper, TextField, Grid, Toolbar, Snackbar, Alert } from '@mui/material';

import contactImage from '../assets/images/contact.png'

import NavBar from '../components/NavBar';
import Footer from '../components/Footer'
import Hero from './LandingPage/Hero/Hero';
import OurServices from './LandingPage/OurServices/OurServices';
import About from './LandingPage/About/About'
import About1 from './LandingPage/About/About1'
import About2 from './LandingPage/About/About2'
import About3 from './LandingPage/About/About3'
import About4 from './LandingPage/About/About4'
import ContactUs from './LandingPage/ContactUs/ContactUs'

const sectionStyles = {
    alignItems: 'center',
    textAlign: 'center',
    //minHeight: '100vh',
    width:'100%'
}

const homeStyles = {
    ...sectionStyles,
};

const aboutUsStyles = {
    ...sectionStyles,
    background: '#ffffff',
    minHeight: "100vh",
};

const about1UsStyles = {
    ...sectionStyles,
    background: '#f1f1f5',
    minHeight: "100vh",
};

const about2UsStyles = {
    ...sectionStyles,
    background: '#fff',
    minHeight: "100vh",
};

const about3UsStyles = {
    ...sectionStyles,
    background: '#fff',
    minHeight: "100vh",
};

const about4UsStyles = {
    ...sectionStyles,
    background: '#fff',
    minHeight: "100vh",
};
const servicesStyles = {
    ...sectionStyles,
    background: '#B2D3C0',
    color: '#ffffff',
    minHeight: "100vh",
};

const contactStyles = {
    ...sectionStyles,
    background: '#ededed',
    minHeight: "100vh",
};

class CompanyWebsite extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeNavItem: 'home',
            socialNavVisible: true,
            name: '',
            email: '',
            message: '',
            homeRef: React.createRef(),
            aboutRef: React.createRef(),
            contactRef: React.createRef(),
            servicesRef: React.createRef(),
            openSnackbar: false,
            snackbarMessage: '',
            snackbarSeverity: 'success',
        };
    }


    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        this.handleNavItemClick(this.state.activeNavItem)

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = (e) => {
        const scrollPosition = Math.trunc(e.target.scrollTop);
        if (scrollPosition === this.state.homeRef.current.offsetTop) {
            this.setState({ activeNavItem: "home", socialNavVisible: true })
        }
        else if (scrollPosition === this.state.aboutRef.current.offsetTop) {
            this.setState({ activeNavItem: "about", socialNavVisible: false })
        }
        else if (scrollPosition === this.state.servicesRef.current.offsetTop) {
            this.setState({ activeNavItem: "services", socialNavVisible: false })
        }
        else if (scrollPosition === this.state.contactRef.current.offsetTop) {
            this.setState({ activeNavItem: "contact", socialNavVisible: false })
        }

    };

    // Function to handle navbar item clicks and scroll to the section
    handleNavItemClick = (sectionId) => {
        this.setState({
            activeNavItem: sectionId,
            home: false,
            about: false,
            contact: false,
            services: false,
        });

        switch (sectionId) {
            case 'home':
                this.setState({ home: true });
                this.state.homeRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'about':
                this.setState({ about: true });
                this.state.aboutRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'contact':
                this.setState({ contact: true });
                this.state.contactRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'services':
                this.setState({ services: true });
                this.state.servicesRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            default:
                break;
        }
    };

    handleFormSubmit = (event) => {
        event.preventDefault();
        this.setState({ name: '', email: '', message: '' });
    };

    handleSnackbarOpen = ({ message, severity }) => {
        this.setState({
            openSnackbar: true,
            snackbarMessage: message,
            snackbarSeverity: severity,
        });
    };

    handleSnackbarClose = () => {
        this.setState({
            openSnackbar: false,
        });
    }
        render() {
            const { openSnackbar, snackbarMessage, snackbarSeverity } = this.state;
            return (

                <Container
                    maxWidth="true"
                    style={{
                        // msScrollSnapDestination: "0 0", /* code added */
                        // scrollSnapDestination: "0 0", /* code added */
                        // msScrollSnapType: "y mandatory", /* code added */
                        // scrollSnapType: 'y mandatory',
                        // overflowY: "scroll",
                        height: "100vh"
                    }}
                    sx={{ paddingLeft: "0px !important", paddingRight: "0px !important" }}
                    onScroll={(e) => { this.handleScroll(e) }}>
                    <NavBar handleNavItemClick={this.handleNavItemClick}
                        activeNavItem={this.state.activeNavItem}
                        socialNavVisible={this.state.socialNavVisible}
                    />
                    <Toolbar />

                    {/* HOME SECTION */}

                    <Grid container  id="home" ref={this.state.homeRef} className='section' style={homeStyles}>
                        <Hero />
                    </Grid>
                    {/* ABOUT SECTION */}
                    <Grid container ref={this.state.aboutRef} className='section about' id="about1" style={about1UsStyles}>
                        <About1 handleNavItemClick={this.handleNavItemClick}/>
                    </Grid>
                    <Grid container className='section' id="about" style={aboutUsStyles}>
                        <About />
                    </Grid>
                    {/* SERVICES SECTION */}
                    <Grid container ref={this.state.servicesRef} id="services" className='section' style={servicesStyles}>
                        <OurServices handleNavItemClick={this.handleNavItemClick} />
                    </Grid>
                    {/* CONTACT_US SECTION */}
                    <Grid container id="contact" ref={this.state.contactRef} className='section' style={contactStyles}>
                        <ContactUs onSnackbarOpen={this.handleSnackbarOpen} />
                    </Grid>
                    <Footer />
                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={this.handleSnackbarClose}
                    >
                        <Alert onClose={this.handleSnackbarClose} severity={snackbarSeverity}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Container >
            );
        }
    }

export default CompanyWebsite;