import './App.css';
import CompanyWebsite from './pages/CompanyWebsite';
import AuthPage from './pages/Auth/AuthPage';
import Dashboard from './pages/Dashboard';
import Sites from './pages/Dashboard/Sites';
import Users from './pages/Dashboard/Users';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Index from './pages/Dashboard/Index';
import { useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import Profile from './pages/Dashboard/Profile';
import ServicePriceDetail from './pages/ServicePriceDetail';
import ReadMore from './pages/MoreAbout/ReadMore';

const theme = createTheme({
  // Define your light mode theme here
  palette: { mode: 'light',
  primary: {
    main : "#F1ECEC"
  }  
},
  typography : {fontFamily : "Inter, sans-serif"},
});

theme.typography.h3 = {
  fontFamily : "Inter, sans-serif",
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.4rem',
  },
};

const darkTheme = createTheme({
  // Define your dark mode theme here
  palette: { mode: 'dark' }
});
function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const toggleDarkMode = () => {
    setIsDarkMode((prev) => !prev);
  };
  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<CompanyWebsite />} />
          <Route path="/ReadMore" element={<ReadMore />} />
          <Route path="/servicePrice" element={<ServicePriceDetail />} />
          <Route path="/AuthPage" element={<AuthPage />} />
          <Route path="/dashboard" element={<Dashboard isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />} >
            <Route path="" element={<Index />} />
            <Route path="sites" element={<Sites />} />
            <Route path="users" element={<Users />} />
            <Route path="profile" element={<Profile />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App;