import React, { useState, useRef } from 'react';
import {  Container, Grid, Toolbar,Typography } from '@mui/material';

import { useNavigate } from 'react-router-dom';



import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer'
import About2 from './../LandingPage/About/About2'
import About3 from './../LandingPage/About/About3'
import About4 from './../LandingPage/About/About4'
import ContactUs from './../LandingPage/ContactUs/ContactUs'
import Differentiators from '../LandingPage/About/Differentiators';

const sectionStyles = {
    alignItems: 'center',
    textAlign: 'center',
    minHeight: '100vh',
    scrollSnapAlign: 'start',
    scrollSnapStop: 'always'
}

const about2UsStyles = {
    ...sectionStyles,
    background: '#fff',
    minHeight: '80vh',

};

const about3UsStyles = {
    ...sectionStyles,
    background: '#fff',
};

const about4UsStyles = {
    ...sectionStyles,
    background: '#fff',
};

const contactStyles = {
    ...sectionStyles,
    background: '#ededed',
};

export default function ReadMore() {
    const navigate = useNavigate();

    const [activeNavItem, setActiveNavItem] = useState("about");
    const [socialNavVisible, setSocialNavVisible] = useState(true);
    const contactUsRef = useRef(null);
 

    // Function to handle navbar item clicks and scroll to the section
    const handleNavItemClick = (sectionId) => {
        console.log(sectionId)
        navigate("/",{ state:{activeNavItem:sectionId}, replace:false });
    };

    const toContactUsSection = ()=>{
        contactUsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }


        return (
            <Container
                maxWidth="false"
                style={{
                    // msScrollSnapDestination: "0 0", /* code added */
                    // scrollSnapDestination: "0 0", /* code added */
                    // msScrollSnapType: "y mandatory", /* code added */
                    // scrollSnapType: 'y mandatory',
                    // overflowY: "scroll",
                    // height: "100vh"
                }}
                sx={{paddingLeft:"0px !important",paddingRight:"0px !important"}}
                >
                <NavBar handleNavItemClick={handleNavItemClick}
                    activeNavItem={activeNavItem}
                    socialNavVisible={socialNavVisible}
                />
                <Toolbar />
                <Grid className='section about' container md={12}  sx={{pt:12, alignItems:'center'}}>
                <Typography variant="h4" component="h4" gutterBottom>
                    COLLABORATION, NETWORK & SECURITY , SOFTWARE DEVELOPMENT
                </Typography>
                </Grid>   
                {/* ABOUT SECTION */}
                <Grid container className='section about' id="about2" style={about2UsStyles}>
                    <About2 toContactUsSection={toContactUsSection}/>
                </Grid>                
                <Grid container className='section about' id="about3" style={about3UsStyles}>
                    <About3 toContactUsSection={toContactUsSection}/>
                </Grid>
                <Grid container className='section about' id="about4" style={about4UsStyles}>
                    <About4 toContactUsSection={toContactUsSection}/>
                </Grid>
{/*                <Grid container className='section differentiators' style={about4UsStyles}>
                    <Differentiators/>
                </Grid>*/}
                {/* CONTACT_US SECTION */}
                <Grid container ref={contactUsRef} id="contact" className='section' style={contactStyles}>
                    <ContactUs/>
                </Grid>
                <Footer />
            </Container >
        );
    
}