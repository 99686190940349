import React, { useState,useEffect  } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Modal,
  TextField,
  Box,
} from '@mui/material';

import { createUser, updateUser, getUsers, deleteUser } from './services/userService';

const Users = () => {
  // Define state variables for user data and modal control
  const [users, setUsers] = useState([]); // Replace with your user data state
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null); // User data for edit or add


  useEffect(() => {
    // Fetch users when the component mounts
    getUsers()
      .then((data) => setUsers(data))
      .catch((error) => console.error('Error fetching users:', error));
  }, []);


  // Function to open the modal for user creation or editing
  const handleOpenModal = (user) => {
    setSelectedUser(user);
    setOpenModal(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setSelectedUser(null);
    setOpenModal(false);
  };

  // Function to save user data (add or edit)
  const handleSaveUser = () => {
    // Implement your logic to save user data here
    // You can send an API request to your backend to create or update a user
    // After successful save, update the users state and close the modal
    // Example:
    // api.saveUser(selectedUser).then((response) => {
    //   // Handle the response and update the users state
    //   setUsers(/* Updated user data */);
    //   handleCloseModal();
    // });
  };

  // Function to delete a user
  const handleDeleteUser = (userId) => {
    // Implement your logic to delete a user here
    // You can send an API request to your backend to delete the user by userId
    // After successful deletion, update the users state
    // Example:
    // api.deleteUser(userId).then(() => {
    //   // Handle the deletion and update the users state
    //   setUsers(/* Updated user data */);
    // });
  };

  return (
    <div>
      <h2>User Management</h2>
      <Button variant="contained" color="primary" onClick={() => handleOpenModal(null)}>
        Add User
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user._id}</TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOpenModal(user)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDeleteUser(user.id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* User Add/Edit Modal */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <h3>{selectedUser ? 'Edit User' : 'Add User'}</h3>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={selectedUser ? selectedUser.name : ''}
            onChange={(e) => setSelectedUser({ ...selectedUser, name: e.target.value })}
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={selectedUser ? selectedUser.email : ''}
            onChange={(e) => setSelectedUser({ ...selectedUser, email: e.target.value })}
          />
          <Button variant="contained" color="primary" onClick={handleSaveUser}>
            Save
          </Button>
          <Button variant="contained" color="inherit" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default Users;
