import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const Sites = () => {
  // Sample data for sites (you can replace this with real data)
  const initialSites = [
    { id: 1, name: 'Site 1' },
    { id: 2, name: 'Site 2' },
    { id: 3, name: 'Site 3' },
    { id: 3, name: 'Site 3' },
  ];

  // State to manage the list of sites
  const [sites, setSites] = useState(initialSites);

  // Function to delete a site by ID
  const deleteSite = (id) => {
    const updatedSites = sites.filter((site) => site.id !== id);
    setSites(updatedSites);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container >
        {sites.map((site) => (
          <Card style={{margin : "5px"}} variant="outlined" xs={4} >
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {site.name}
              </Typography>
              <Typography variant="h5" component="div">
                benevolent
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                adjective
              </Typography>
              <Typography variant="body2">
                well meaning and kindly.
                <br />
                {'"a benevolent smile"'}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small">Learn More</Button>
            </CardActions>
          </Card>
        ))}
      </Grid>
    </Box>
  );
};

export default Sites;
