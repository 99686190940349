// About.js

import React from 'react';
import './About.css';
import { Typography, Avatar, Card, CardContent, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import withoutCommitment from '../../../assets/images/without-commitment.png';
import personalizedSupport from '../../../assets/images/personalized-support.png';
import tailoredOffer from '../../../assets/images/tailored-offer.png';

export default function About() {
  const { t } = useTranslation('about');

  return (
    <Grid
      container
      item
      xs={12}
      direction="row"
      justifyContent="space-evenly"
      alignItems="stretch"
      className="box-container"
    >
      <Grid item xs={12}>
        <Typography mb={12} variant="h4" component="h4" className="title">
          {t('titles.0')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <Card sx={{ p: 3, mt: { xs: 6, md: 0 }, mx: { xs: 6, md: 0 } }} className="root">
          <Avatar className="avatar" sx={{ borderRadius: '0' }}>
            <img src={personalizedSupport} width="256" height="256" alt="" title="" className="img-small" />
          </Avatar>
          <CardContent sx={{ p: 0 }} className="cardContent">
            <Typography variant="h6" component="h6">
              {t('titles.1')}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {t('descriptions.0')}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={3}>
        <Card sx={{ p: 3, mt: { xs: 6, md: 6 }, mx: { xs: 6, md: 0 } }} className="root middle">
          <Avatar className="avatar" sx={{ borderRadius: '0' }}>
            <img src={tailoredOffer} width="256" height="256" alt="" title="" className="img-small" />
          </Avatar>
          <CardContent sx={{ p: 0 }} className="cardContent">
            <Typography variant="h6" component="h6">
              {t('titles.2')}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {t('descriptions.1')}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={3}>
        <Card sx={{ p: 3, mt: { xs: 6, md: 0 }, mx: { xs: 6, md: 0 } }} className="root">
          <Avatar className="avatar" sx={{ borderRadius: '0' }}>
            <img src={withoutCommitment} width="256" height="256" alt="" title="" className="img-small" />
          </Avatar>
          <CardContent sx={{ p: 0 }} className="cardContent">
            <Typography variant="h6" component="h6">
              {t('titles.3')}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {t('descriptions.2')}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
