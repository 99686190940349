// ContactUsForm.js

import React, { useState } from 'react';
import { TextField, Button, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';
import emailjs from 'emailjs-com';

const ContactUsForm = ({ onClose, onSnackbarOpen }) => {
  const { t } = useTranslation('contactUsForm');

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.init({ publicKey: 'YOUR_PUBLIC_KEY_HERE' });

    const serviceId = 'service_hjxbmpt';
    const templateId = 'template_glc7awa';

    const templateParams = {
      from_name: name,
      from_email: email,
      message: message,
    };

    emailjs.send(serviceId, templateId, templateParams, "YOUR_PUBLIC_KEY_HERE")
      .then((result) => {
        console.log('Email sent successfully:', result.text);
        onSnackbarOpen({ message: t('successMessage'), severity: 'success' });
        onClose();
      }, (error) => {
        console.error('Email send error:', error.text);
        // Handle errors, show an error message, etc.
      });

    setName('');
    setEmail('');
    setMessage('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label={t('name')}
        fullWidth
        margin="normal"
        variant="outlined"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <TextField
        label={t('email')}
        fullWidth
        margin="normal"
        variant="outlined"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <TextField
        label={t('message')}
        fullWidth
        multiline
        rows={4}
        margin="normal"
        variant="outlined"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        required
      />
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          {t('cancel')}
        </Button>
        <Button type="submit" variant="contained" color="success">
          {t('send')}
        </Button>
      </DialogActions>
    </form>
  );
};

export default ContactUsForm;
